<template>
  <app-form-autocomplete
    v-model="model"
    v-model:query="queryModel"
    :autofocus="autofocus"
    data-cy-attendee-autocomplete
    :exclude="exclude"
    hide-label
    hide-selected
    item-text="item.full_name"
    item-value="item.email"
    :items="results"
    label="Participants"
    multiple
    :placeholder="placeholder"
    prepend-icon="ph:magnifying-glass"
    :readonly="readonly"
    @close="$emit('close')"
    @open="$emit('open')"
  >
    <template #item="{ item }">
      <user-overview
        :avatar-size="32"
        class="text-sm"
        size="small"
        :user="item.item"
      />
    </template>

    <template v-if="$slots.empty" #empty="{ close }">
      <slot name="empty" v-bind="{ close }" />
    </template>

    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import { useFuse } from "@vueuse/integrations/useFuse";

import type { OrganizationAttendeeUserFields } from "~/business-areas/organization-attendee/organization-attendee.model";
import type { I18nMessage } from "~/core/i18n/i18n.model";

const properties = withDefaults(
  defineProps<{
    modelValue?: OrganizationAttendeeUserFields[];
    query?: string;
    autofocus?: boolean;
    exclude?: DatabaseTable<"users">["email"][];
    readonly?: boolean;
    placeholder?: I18nMessage;
    additionalItems?: OrganizationAttendeeUserFields[];
  }>(),
  {
    modelValue: undefined,
    exclude: undefined,
    query: "",
    placeholder: undefined,
    additionalItems: () => [],
  },
);

const queryModel = useVModel(properties, "query", undefined, {
  passive: true,
  defaultValue: "",
});

const emit = defineEmits<{
  "update:modelValue": [value: typeof properties.modelValue];
  open: [];
  close: [];
}>();

defineSlots<{
  empty(properties: { close(): void }): void;
  append(): void;
}>();

const model = computed<DatabaseTable<"users">["email"][]>({
  get() {
    return properties.modelValue?.map((user) => user.email) ?? [];
  },
  set(value) {
    emit("update:modelValue", [
      ...properties.additionalItems,
      ...value
        .map((email) => data.value?.find((user) => user.email === email))
        .filter(Boolean),
    ]);
  },
});

const { organization } = useCurrentOrganization();

const { data } = useAsyncData(async () => {
  const [attendees, members] = await Promise.all([
    $fetch(`/api/organizations/${organization.value!.id}/attendees`),
    $fetch(`/api/organizations/${organization.value!.id}/members`),
  ]);

  const attendeeEmails = new Set(attendees.map((a) => a.email));

  return [
    ...attendees,
    ...members
      .filter((m) => !attendeeEmails.has(m.user.email))
      .map((m) => m.user),
  ];
});

const { results } = useFuse(
  queryModel,
  computed(() => data.value ?? []),
  {
    fuseOptions: {
      keys: ["first_name", "last.name", "email"],
      threshold: 0.3,
    },
    matchAllWhenSearchEmpty: true,
  },
);
</script>
