<template>
  <combobox
    ref="wrapper"
    v-model="value"
    as="div"
    class="relative"
    :multiple="multiple"
  >
    <app-form-field
      :class="{
        '!rounded-b-none':
          showOptions && (filteredItems.length > 0 || $slots.empty),
      }"
      v-bind="fieldBinding"
      label-element-as="div"
    >
      <template #default="{ inputClass }">
        <combobox-button
          class="w-full h-full"
          @click="!readonly && toggleOptions(true)"
        >
          <combobox-input
            ref="input"
            autocomplete="off"
            :class="inputClass"
            :placeholder="placeholder"
            :readonly="readonly"
            :value="query"
            @input="query = $event.target.value"
            @keyup.escape.stop="toggleOptions(false)"
          />
        </combobox-button>
      </template>

      <template v-if="$slots.append" #append>
        <slot name="append" />
      </template>
    </app-form-field>

    <transition
      enter-active-class="transition"
      enter-from-class="opacity-0 translate-y-1"
      leave-active-class="transition"
      leave-to-class="opacity-0 translate-y-1"
    >
      <combobox-options
        v-show="showOptions && (filteredItems.length > 0 || $slots.empty)"
        class="p-1 shadow-lg absolute z-10 min-w-full overflow-auto rounded-b border border-b-form-focus border-x-form-focus bg-white outline-none"
        :class="[
          positionClasses,
          {
            'max-h-[250px]': filteredItems.length > 0,
          },
        ]"
        static
      >
        <combobox-option
          v-for="item in filteredItems"
          :key="get(item, itemValue)"
          v-slot="{ selected, active }"
          as="li"
          :value="get(item, itemValue)"
          @click="toggleOptions(false)"
        >
          <div
            class="p-2 cursor-pointer rounded"
            :class="{
              'bg-primary-50 ': active,
              'bg-primary text-white': selected,
            }"
          >
            <slot name="item" v-bind="{ item }">
              {{ get(item, itemText) }}
            </slot>
          </div>
        </combobox-option>

        <div
          v-if="$slots.empty && filteredItems.length === 0"
          ref="empty"
          @click.stop
        >
          <slot name="empty" v-bind="{ close: () => toggleOptions(false) }" />
        </div>
      </combobox-options>
    </transition>
  </combobox>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>">
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import get from "lodash/get";
import type { ComponentPublicInstance, Ref } from "vue";

import {
  type DropdownPosition,
  useDropdownPosition,
} from "../../dropdown/composables/dropdown-position.hook";
import {
  appFormInputProperties,
  useAppFormInput,
} from "../composables/form-input.hook";

const properties = defineProps({
  ...appFormInputProperties,
  modelValue: { type: [String, Array], default: undefined },
  query: { type: String, default: "" },
  items: { type: Array, default: () => [] },
  itemText: { type: String, required: true },
  itemValue: { type: String, required: true },
  multiple: { type: Boolean, default: false },
  hideSelected: { type: Boolean, default: false },
  position: {
    type: String,
    default: "bottom-left",
  },
  exclude: { type: Array, default: () => [] },
});

const emit = defineEmits(["open", "close"]);

defineSlots<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item(props: { item: any }): void;
  empty(props: { close(): void }): void;
  append(): void;
}>();

const filteredItems = computed(() => {
  const whitelistedItems = properties.items.filter(
    (item) => !properties.exclude.includes(get(item, properties.itemValue)),
  );

  if (
    !properties.hideSelected ||
    !properties.modelValue ||
    (Array.isArray(properties.modelValue) && properties.modelValue.length === 0)
  )
    return whitelistedItems;

  return whitelistedItems.filter((i) => {
    if (Array.isArray(properties.modelValue))
      return !properties.modelValue.includes(get(i, properties.itemValue));

    return properties.modelValue !== get(i, properties.itemValue);
  });
});

const query = useVModel(properties, "query");
const { value, fieldBinding } = useAppFormInput(properties);
const { class: positionClasses } = useDropdownPosition(
  properties.position as DropdownPosition,
  { offset: false },
);

watch(value, () => (query.value = ""));

const input = templateRef("input") as Ref<ComponentPublicInstance | null>;
if (properties.autofocus) {
  onMounted(() => input.value?.$el.focus());
}
const [showOptions, toggleOptions] = useToggle();

watch(showOptions, (isOpen) => emit(isOpen ? "open" : "close"));

watch(query, (value) => {
  if (!value) return;

  toggleOptions(true);
});

const empty = templateRef<HTMLDivElement>("empty");
const wrapper = templateRef<HTMLDivElement>("wrapper");
onClickOutside(wrapper, () => toggleOptions(false), { ignore: [empty] });
</script>
