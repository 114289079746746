<template>
  <app-dialog
    :scrollable="false"
    :submitting="status === 'pending'"
    :title="session.course.title"
    :width="950"
    @submit="execute"
  >
    <template #subtitle>
      <session-registration-dialog-header :session="session" />
    </template>

    <div class="min-h-[300px]">
      <organization-attendee-select-widget
        ref="selectWidget"
        v-model="toBeRegistered"
        autofocus
        :exclude="registeredAttendees?.map((attendee) => attendee.user.email)"
        :max="session.remaining_seats!"
      >
        <template #append>
          <div class="flex items-center gap-2 text-xs">
            <app-gauge-chart
              class="!text-accent"
              :max="session.remaining_seats!"
              :size="18"
              :stroke-width="3"
              :value="toBeRegistered.length"
            />
            <i18n-t
              keypath="registration.remaining_seats_label"
              :plural="session.remaining_seats! - toBeRegistered.length"
              tag="p"
            >
              <template #count>
                <strong>{{
                  session.remaining_seats! - toBeRegistered.length
                }}</strong>
              </template>
            </i18n-t>
          </div>
        </template>
      </organization-attendee-select-widget>

      <ul v-if="toBeRegistered.length > 0" class="mt-4 flex flex-wrap gap-2">
        <li
          v-for="user in toBeRegistered"
          :key="user.email"
          class="flex items-center gap-2 text-sm"
        >
          <app-chip class="bg-primary-50" deletable @delete="onDelete(user)">
            <app-user-avatar
              :avatar-url="user.avatar_url"
              :name="user.full_name"
              :size="24"
            />
            {{ user.full_name }}
          </app-chip>
        </li>
      </ul>

      <section v-if="!!registeredAttendees?.length" class="mt-6">
        <header class="mb-2">
          <h3 class="font-semibold">Déjà inscrit.es</h3>
        </header>

        <ul class="grid grid-cols-4 gap-x-4 gap-y-2">
          <li
            v-for="attendee in registeredAttendees"
            :key="attendee.user.id"
            class="flex items-center gap-2 text-sm"
          >
            <app-user-avatar
              :avatar-url="attendee.user.avatar_url"
              :name="attendee.user.full_name"
              :size="24"
            />
            {{ attendee.user.full_name }}
          </li>
        </ul>
      </section>
    </div>

    <template #footer>
      <div class="flex items-center justify-between">
        <app-button
          data-cy-csv-import
          variant="tonal"
          @click="onUseCsvImportClick"
        >
          {{ $t("registration.csv_import_cta") }}
        </app-button>
        <app-button :disabled="toBeRegistered.length === 0" type="submit">
          {{
            $t("registration.validation_cta", { count: toBeRegistered.length })
          }}
        </app-button>
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import { showErrorToastNotification } from "@pollen/ui-library/client/shared/toast-notification/composables/toast-notification.hook";

import type { OrganizationAttendeeUserFields } from "~/business-areas/organization-attendee/organization-attendee.model";
import type { SessionListView } from "~/business-areas/session/session.model";

const properties = defineProps<{
  session: SessionListView;
}>();

const { organization } = useCurrentOrganization();
const { data: registeredAttendees } = await useFetch(
  `/api/organizations/${organization.value!.id}/sessions/${
    properties.session.id
  }/attendees`,
  {
    query: {
      time_window: "UPCOMING",
      sold_out: false,
      self_serve_registration: true,
      attendees_list_limit: "none",
      sessions: [properties.session.id],
    },
  },
);

const toBeRegistered = ref<OrganizationAttendeeUserFields[]>([]);

function onDelete(user: OrganizationAttendeeUserFields) {
  toBeRegistered.value = toBeRegistered.value.filter(
    (u) => u.email !== user.email,
  );
}

const { $emit } = useNuxtApp();
const { t } = useI18n();
const { execute, status } = useAsyncData(
  `session_registration_${properties.session.id}`,
  async () => {
    try {
      await $fetch(
        `/api/organizations/${organization.value!.id}/sessions/${
          properties.session.id
        }/attendees`,
        {
          method: "POST",
          body: toBeRegistered.value,
        },
      );

      showSuccessToastNotification(
        t("registration.submission_success_message"),
      );

      $emit("session_registration_created");
      useDialog("SessionRegistrationDialog").close({ confirmed: true });
    } catch {
      showErrorToastNotification(t("registration.submission_error_message"));
    }
  },
  {
    immediate: false,
  },
);

function onUseCsvImportClick() {
  return useDialog("SessionBatchRegistrationDialog").open({
    session: properties.session,
  });
}
</script>
