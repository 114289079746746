<template>
  <div
    class="text-primary relative w-[--size] h-[--size] grid place-content-center"
    :style="{ '--size': `${size}px` }"
  >
    <svg class="absolute inset-0" :height="size" :width="size">
      <circle
        class="opacity-10"
        :cx="size / 2"
        :cy="size / 2"
        fill="transparent"
        :r="radius"
        stroke="currentColor"
        :stroke-width="strokeWidth / 1.5"
      />

      <circle
        class="transition duration-500 transition-[stroke-dashoffset]"
        :cx="size / 2"
        :cy="size / 2"
        fill="transparent"
        :r="radius"
        stroke="currentColor"
        :stroke-dasharray="dasharray"
        :stroke-dashoffset="dashoffset"
        stroke-linecap="round"
        :stroke-width="strokeWidth"
        :transform="`rotate(270, ${size / 2}, ${size / 2})`"
      />
    </svg>

    <slot />
  </div>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    size?: number;
    strokeWidth?: number;
    value?: number;
    max?: number;
  }>(),
  {
    size: 60,
    strokeWidth: 6,
    value: 0,
    max: 100,
  },
);

const radius = computed(() => (properties.size - properties.strokeWidth) / 2);
const dasharray = computed(() => {
  const circumference = radius.value * 2 * Math.PI;

  return `${circumference} ${circumference}`;
});

const dashoffset = computed(() => {
  const circumference = radius.value * 2 * Math.PI;
  const progress = properties.value / properties.max;

  return circumference - progress * circumference;
});
</script>
